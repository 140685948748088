import {BaseService} from "@/core/services/BaseService";

class PublicFormService extends BaseService<any> {
  endpoint = "/custom-form"

  async getForm(source) {
   return this.http().get(this.endpoint + "/source?source=" + source).then(res => {
      return res.data
    })
  }
}

export default new PublicFormService();